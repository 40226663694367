import Layout from '@/components/Layout/Layout';
import SEO from '@/components/SEO/Seo';
import AllBrands from '@motokiki/shared/allBrands';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import { graphql } from 'gatsby';
import React from 'react';

import GeneralTextBlock from '../../components/GeneralTextBlock';
import SearchWidgetContainer from '../../components/SearchWidgetContainer';
import './TyreBrandsIndex.scss';

interface Props {
  data: {
    strapi: {
      tyreBrandsIndex: {
        brands: Array<{
          sort: (dynamicSort) => void;
          brandDescription: string;
          brandName: string;
          brandLogo: {
            url: string;
          };
        }>;
        TextBlock: {
          textBlock: string;
        };
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          lozengeText: string;
          iconImg: {
            url: string;
          };
          mainImg: {
            url: string;
          };
        };
      };
    };
  };
}

const TyreBrandsIndex = ({ data }: Props) => {
  const { HeroSection, TextBlock, brands } = data.strapi.tyreBrandsIndex;

  const dynamicSort = (property) => {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };

  const alphabeticallySortedBrands = brands.sort(dynamicSort('brandName'));

  console.log(alphabeticallySortedBrands);
  return (
    <>
      <SEO />
      <Layout>
        <GeneralHeroSec data={HeroSection} />
        <div className="pageContent">
          <GeneralTextBlock textData={TextBlock} />
          <h2 className="header-h2">Tyre Brands</h2>
        </div>
        <div className="tyreBrandsContainer">
          {alphabeticallySortedBrands.map((tyreBrand) => {
            return (
              <a
                className="brandCard"
                href={`/tyres/brands/${tyreBrand.brandName}`}
                key={tyreBrand.brandName}
              >
                <div className="logoHolder">
                  <img className="logo" src={tyreBrand.brandLogo.url} />
                </div>
                <div className="name">{tyreBrand.brandName}</div>
              </a>
            );
          })}
        </div>
        <SearchWidgetContainer />
      </Layout>
    </>
  );
};

export const tyreBrandsIndex = graphql`
  query TyreBrandsIndex {
    strapi {
      tyreBrandsIndex(publicationState: PREVIEW) {
        brands {
          brandDescription
          brandName
          brandLogo {
            url
          }
        }
        TextBlock {
          textBlock
        }
        HeroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          lozengeText
          iconImg {
            url
          }
          mainImg {
            url
          }
        }
      }
    }
  }
`;

export default TyreBrandsIndex;
